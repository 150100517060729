const cookies = () => {
  const el = document.getElementById('cookies-bar')

  if (el === null) return;

  const close = el.querySelector('button')
  const moreInfo = el.querySelector('a')


  if (localStorage.getItem('AHCO_COOKIES_ACCEPTED') != 'TRUE') {
    el.classList.remove('closed')
  }

close.addEventListener('click', function(e) {
    if (e.target != moreInfo) {
      el.classList.add('closed')
      localStorage.setItem('AHCO_COOKIES_ACCEPTED', 'TRUE')
    }
  })
}

cookies();
