import { Controller } from "stimulus"

export default class extends Controller {
  modalOn(e) {
    const allModals = this.targets.findAll('modal')
    const currentModal = this.targets.findAll('modal')[e.currentTarget.dataset.id]

    allModals.forEach(function(modal) { modal.classList.remove('active') })
    currentModal.classList.add('active')
  }

  modalOff(e) {
    const allModals = document.querySelectorAll('.el-grid_item') // this.targets.findAll('modal')

    Array.from(allModals).forEach(function(modal) {
      modal.classList.remove('active')
    })
  }
}
