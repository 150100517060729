import { Controller } from "stimulus";
import 'swiper/swiper-bundle.min.css';

import Swiper, { Pagination } from "swiper/swiper-bundle";

export default class extends Controller {
  static targets = ["pagination"];

  constructor(ctx) {
    super(ctx);
    Swiper.use([Pagination]);
    this.swiper = null;
  }

  connect() {
    const options = {};
    if (this.hasPaginationTarget)
      options.pagination = {
        el: this.paginationTarget,
        loop: true,
        grabCursor: true,
        slidesPerView: 1,
        type: "bullets",
        bulletActiveClass: "swiper-pagination-bullet-active--custom",
      };
    this.swiper = new Swiper(this.element, options);
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = null;
  }
}
