import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['toggle']
  static values = { text: String }

  connect() {
    const element = this.element
    if (this.isOverflowingBody(element)) {
      element.contentTarget.classList.add('show--below')
      if (this.isOverflowingBody(element)) {
        element.contentTarget.classList.remove('show--below')
        element.contentTarget.classList.add('show--left')
      }
    }
  }

  isOverflowingBody(element) {
    const bodyRect = document.body.getBoundingClientRect()
    const elRect = element.getBoundingClientRect();

    return elRect.right > bodyRect.width
  }

  show() {
    const content = document.createTextNode(this.textValue);
    const el = document.createElement('div');
    const para = document.createElement('p');
    para.appendChild(content);
    el.className = 'footnote-popup'
    el.appendChild(para)
    this.element.appendChild(el)
  }
  remove() {
    const popup = this.element.querySelector('.footnote-popup')
    popup.remove()

  }
}
